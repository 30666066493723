@use './mixins' as mixin;

@include mixin.font-face('SourceSans3', 'SourceSans3-ExtraLight', 200);
@include mixin.font-face('SourceSans3', 'SourceSans3-Light', 300);
@include mixin.font-face('SourceSans3', 'SourceSans3-Regular', 400);
@include mixin.font-face('SourceSans3', 'SourceSans3-Medium', 500);
@include mixin.font-face('SourceSans3', 'SourceSans3-SemiBold', 600);
@include mixin.font-face('SourceSans3', 'SourceSans3-Bold', 700);
@include mixin.font-face('SourceSans3', 'SourceSans3-ExtraBold', 800);
@include mixin.font-face('SourceSans3', 'SourceSans3-Black', 900);
