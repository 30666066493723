@use './functions' as func;

h1,
h2,
h3,
p {
  color: func.getColor(text-primary);
  margin: 0;
  padding: 0;
}

h1,
h2,
h3 {
	font-style: normal;
	font-weight: 500;
}

h1 {
  font-size: 1.75rem;
	line-height: 2.5rem;
}

h2 {
	font-size: 1.375rem;
	line-height: 2rem;
}

h3 {
	font-size: 1rem;
	line-height: 1.5rem;
}

a {
	text-decoration: none;
  color: func.getColor(action-button-primary-text-default);
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125rem;

  &:hover {
    color: func.getColor(action-button-primary-text-hovered);
  }
}

p {
	font-size: 1rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.5rem;
}

// TODO remove all styles below

.text-14 {
	font-size: 0.875rem;
	line-height: 1.25rem;
}

.semibold {
	font-weight: 600;
}

