@use './functions' as func;

@import '@angular/cdk/overlay-prebuilt.css';

// Overlay backdrop styling
.eui-overlay-backdrop {
	background-color: func.getColor(background-modal);

	&_disabled {
		opacity: 0 !important;
		pointer-events: none !important;
	}

	&_transparent {
		background-color: transparent;
	}
}

// Overlay panel styling
.eui-overlay-panel {
	display: flex;
	flex-direction: column;

	&_viewport {
		width: 100%;
		align-items: center;
		justify-content: flex-start;
		overflow: auto;
	}
}

.eui-overlay-notifications-push {
	z-index: 999;
}

.eui-overlay-counter {
	z-index: 999;
}

.eui-overlay-toast {
	z-index: 1005;
}
