@use './mixins' as mixin;
@use './variables' as var;

.emrm-c {
	&-sidebar {
		width: var.$emrm-l-sidebar-width;
		min-width: var.$emrm-l-sidebar-width;
		will-change: min-height;

		&-inner {
			transform: translate(0, 0);
			transform: translate3d(0, 0, 0);
			will-change: position, transform;
		}
	}

	&-form-group {
		@include mixin.flex-column();

		justify-content: flex-start;
		width: 100%;
	}
}
